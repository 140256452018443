import React, { PureComponent } from 'react'
import { DsContainer, DsFade } from '@am92/react-design-system'
import Renergize from './Components/Renergize'

export interface IProductsPageProps {}

export default class ProductsPage extends PureComponent<IProductsPageProps> {
  handleDownloadRenergize = () => {
    window.location.href = 'https://renergize.io'
  }

  render() {
    return (
      <DsFade in>
        <DsContainer
          sx={{
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite)'
            },
            pt: {
              xs: 'var(--ds-spacing-tropical)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        >
          <Renergize />
        </DsContainer>
      </DsFade>
    )
  }
}
