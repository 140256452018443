import React, { PureComponent } from 'react'
import {
  DsBox,
  DsImage,
  DsLink,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { Swiper, SwiperSlide } from 'swiper/swiper-react'
import {
  EffectCoverflow,
  Autoplay,
  Navigation,
  Pagination
} from 'swiper/modules'

import topshineHome from '~/src/Assets/portfolio/topshine/topshineHome'
import topshineAboutUs from '~/src/Assets/portfolio/topshine/topshineAboutUs'
import topshineServices from '~/src/Assets/portfolio/topshine/topshineServices'
import topshineProductCategories from '~/src/Assets/portfolio/topshine/topshineProductCategories'
import topshineProductList from '~/src/Assets/portfolio/topshine/topshineProductList'
import topshineProduct from '~/src/Assets/portfolio/topshine/topshineProduct'
import topshineProductOrder from '~/src/Assets/portfolio/topshine/topshineProductOrder'
import topshineSidenav from '~/src/Assets/portfolio/topshine/topshineSidenav'

import 'swiper/swiper.min.css'
import '~/public/assets/css/swiper.css'
import 'swiper/modules/navigation.min.css'
import 'swiper/modules/pagination.min.css'

const sliderImages = [
  topshineHome,
  topshineSidenav,
  topshineAboutUs,
  topshineServices,
  topshineProductCategories,
  topshineProductList,
  topshineProduct,
  topshineProductOrder
]

export interface ITopshineProps {}

export default class Topshine extends PureComponent<ITopshineProps> {
  renderDescription = () => (
    <>
      <DsTypography variant="bodyRegularLarge" color="text.secondary">
        This mobile website has been crafted to exhibit product offerings and
        facilitate order placement through Whatsapp Messenger enabling a swift
        and user-friendly solution for small or lean businesses. The design
        prioritizes optimal performance and swift page loading, leveraging
        Google's{' '}
        <DsLink
          variant="inherit"
          underline="always"
          href="https://developers.google.com/speed/docs/insights/v5/about"
          target="_blank"
        >
          Pagespeed Insights
        </DsLink>{' '}
        to enhance the overall user experience. Implemention on the AWS Cloud
        with Content Delivery Network (CDN) guarantees global accessibility with
        minimized page load times.
      </DsTypography>

      <DsTypography variant="bodyRegularLarge" color="text.secondary">
        This project showcases our commitment to delivering efficient,
        user-centric solutions for businesses aiming to streamline their online
        presence and customer interactions.
      </DsTypography>

      <DsTypography variant="bodyRegularLarge" color="text.secondary">
        You can checkout the complete website experience on{' '}
        <DsLink
          variant="inherit"
          underline="always"
          href="https://topshineindia.com"
          target="_blank"
        >
          https://topshineindia.com
        </DsLink>
      </DsTypography>
    </>
  )

  render() {
    return (
      <DsStack
        id="ramanilegal"
        direction={{ xs: 'column', md: 'row' }}
        spacing="var(--ds-spacing-warm)"
      >
        <DsBox sx={{ flex: 2 }}>
          <DsTypography
            component="div"
            variant="displayBoldLarge"
            sx={{ mb: 'var(--ds-spacing-glacial)' }}
          >
            Topshine
          </DsTypography>
          <DsTypography
            component="div"
            variant="headingBoldExtraLarge"
            color="primary"
          >
            Mobile Website
          </DsTypography>

          <DsStack
            direction="column"
            spacing="var(--ds-spacing-bitterCold)"
            sx={{
              mt: 'var(--ds-spacing-blazing)',
              display: { xs: 'none', md: 'flex' }
            }}
          >
            {this.renderDescription()}
          </DsStack>
        </DsBox>

        <DsBox
          sx={{
            flex: 1,
            pt: 'var(--ds-spacing-warm)',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            overflowY: 'hidden'
          }}
        >
          <Swiper
            loop
            autoplay
            navigation
            pagination={{ clickable: true }}
            grabCursor
            centeredSlides
            effect="coverflow"
            slidesPerView={1.6}
            modules={[EffectCoverflow, Autoplay, Navigation, Pagination]}
            style={{ paddingBottom: 'var(--ds-spacing-tropical)' }}
          >
            {sliderImages.map((sliderImage, index) => (
              <SwiperSlide key={index}>
                <DsImage
                  srcSet={sliderImage}
                  aspectRatio={1170 / 2532}
                  WrapperProps={{
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    boxShadow: 'var(--ds-elevation-0)'
                  }}
                  style={{ display: 'block' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </DsBox>

        <DsStack
          direction="column"
          spacing="var(--ds-spacing-bitterCold)"
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          {this.renderDescription()}
        </DsStack>
      </DsStack>
    )
  }
}
