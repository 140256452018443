const topshineProductOrder = [
  {
    src: new URL(
      '~public/assets/images/portfolio/topshine/productOrder.png?width=300px&as=avif',
      import.meta.url
    ).href,
    alt: 'Product Order',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~public/assets/images/portfolio/topshine/productOrder.png?width=300px',
      import.meta.url
    ).href,
    alt: 'Product Order',
    as: 'image/png'
  }
]

export default topshineProductOrder
