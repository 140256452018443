const ramaniBooksAuthoredMobile = [
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/mobile/booksAuthored.png?width=300px&as=avif',
      import.meta.url
    ).href,
    alt: 'Books Authored',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/mobile/booksAuthored.png?width=300px',
      import.meta.url
    ).href,
    alt: 'Books Authored',
    as: 'image/png'
  }
]

export default ramaniBooksAuthoredMobile
