import React, { PureComponent } from 'react'
import {
  DsBox,
  DsIconButton,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

export interface IHighlightProps extends IWithRouterProps {}

class Highlight extends PureComponent<IHighlightProps> {
  render() {
    const { location } = this.props
    if (location.pathname === APP_ROUTES.PRODUCTS.pathname) {
      return false
    }

    return (
      <DsBox
        sx={{
          backgroundColor: 'var(--ds-colour-actionPrimary)',
          px: {
            xs: 'var(--ds-spacing-glacial)',
            md: 'var(--ds-spacing-warm)'
          },
          py: {
            xs: 'var(--ds-spacing-quickFreeze)',
            md: 'var(--ds-spacing-glacial)'
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <DsTypography component="span">
          Checkout our new App Renergize!
        </DsTypography>
        <DsIconButton
          onClick={() => this.props.navigateTo(APP_ROUTES.PRODUCTS.pathname)}
          sx={{ ml: 'var(--ds-spacing-glacial)' }}
        >
          <DsRemixIcon className="ri-arrow-right-line" />
        </DsIconButton>
      </DsBox>
    )
  }
}

export default withRouter(Highlight)
