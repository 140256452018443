import React, { PureComponent } from 'react'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import { DsBox, DsContainer, DsImage } from '@am92/react-design-system'
import logo from '~/src/Assets/logo'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import NavLinksDesktop from './NavLinksDesktop'
import NavLinksMobile from './NavLinksMobile'

export interface IHeaderProps extends IWithRouterProps {}

class Header extends PureComponent<IHeaderProps> {
  render() {
    return (
      <DsBox
        sx={{
          height: {
            xs: 'var(--ds-rules-headerMobileHeight)',
            lg: 'var(--ds-rules-headerDesktopHeight)'
          },
          backgroundColor: 'var(--ds-colour-actionSecondary)',
          boxShadow: 'var(--ds-elevation-3)'
        }}
      >
        <DsContainer
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            py: {
              xs: 'var(--ds-spacing-frostbite)',
              md: 'var(--ds-spacing-bitterCold)'
            },
            justifyContent: 'space-between'
          }}
        >
          <DsImage
            srcSet={logo}
            WrapperProps={{
              onClick: () => this.props.navigateTo(APP_ROUTES.HOME.pathname),
              sx: { height: '100%', width: 'auto', cursor: 'pointer' }
            }}
            style={{ height: '100%', width: 'auto' }}
          />

          <NavLinksDesktop />
          <NavLinksMobile />
        </DsContainer>
      </DsBox>
    )
  }
}

export default withRouter(Header)
