export const SERVICES = [
  {
    title: 'Web Application Development',
    description:
      'Creating dynamic, interactive web apps, including single-page and progressive solutions tailored to unique business needs.'
  },
  {
    title: 'Mobile App Development',
    description:
      'Building cross-platform or native mobile applications for iOS and Android to reach a wider audience.'
  },
  {
    title: 'Web Design',
    description:
      'Crafting visually appealing and user-friendly designs, ensuring seamless experiences across various devices.'
  },
  {
    title: 'Cloud Solutions',
    description:
      'Transforming your business with streamlined cloud services for scalability, security, and cost-effectiveness.'
  },
  {
    title: 'Web Security Services',
    description:
      'Implementing security measures, including SSL certificates and regular audits, to protect against cyber threats.'
  },
  {
    title: 'API & Microservice Development',
    description:
      'Creating scalable and secure APIs, both RESTful and GraphQL, to facilitate data exchange between applications.'
  },
  {
    title: 'Custom Integrations',
    description:
      'Integrating third-party services and APIs to enhance functionality and connectivity.'
  },
  {
    title: 'Database Development and Management',
    description:
      'Designing, optimizing, and managing databases to ensure efficient data storage and retrieval.'
  },
  {
    title: 'Website Maintenance and Support',
    description:
      'Providing ongoing support, updates, and bug fixes to keep websites running smoothly.'
  },
  {
    title: 'Search Engine Optimization (SEO)',
    description:
      'Optimizing websites to improve search engine rankings and enhance online visibility.'
  },
  {
    title: 'Analytics and Reporting',
    description:
      'Implementing analytics tools and dashboards to track and analyze website performance.'
  },
  {
    title: 'Consulting and Strategy',
    description:
      'Providing expertise in technology and digital strategy to guide businesses in making informed decisions.'
  }
]
