const topshineProductList = [
  {
    src: new URL(
      '~public/assets/images/portfolio/topshine/productList.png?width=300px&as=avif',
      import.meta.url
    ).href,
    alt: 'Product List',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~public/assets/images/portfolio/topshine/productList.png?width=300px',
      import.meta.url
    ).href,
    alt: 'Product List',
    as: 'image/png'
  }
]

export default topshineProductList
