const renergizeShowcase3 = [
  {
    src: new URL(
      '~/public/assets/images/products/renergize/showcase3.png?width=300px&as=avif',
      import.meta.url
    ).href,
    alt: 'Renergize',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~/public/assets/images/products/renergize/showcase3.png?width=300px',
      import.meta.url
    ).href,
    alt: 'Renergize',
    as: 'image/png'
  }
]

export default renergizeShowcase3
