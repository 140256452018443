import React, { PureComponent } from 'react'
import {
  DsBox,
  DsContainer,
  DsFade,
  DsImage,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import ButtonPrimary from '~/src/Components/ButtonPrimary'
import contactUsImage from '~/src/Assets/contactUs/contactUsImage'
import {
  CALL_NUMBER,
  GOOGLE_MAP_LINK,
  MAIL_ID,
  WHATSAPP_NUMBER
} from './ContactUs.Constants'

export interface IContactUsPageProps {}

export default class ContactUsPage extends PureComponent<IContactUsPageProps> {
  handleMailToClick = () => {
    window.location.href = `mailto:${MAIL_ID}`
  }

  handleWhatsappClick = () => {
    window.location.href = `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER.replaceAll(
      ' ',
      ''
    )}`
  }

  handleCallClick = () => {
    window.location.href = `tel:${CALL_NUMBER}`
  }

  handleLocationClick() {
    window.open(GOOGLE_MAP_LINK, '_blank')
  }

  render() {
    return (
      <DsFade in>
        <DsContainer
          sx={{
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite)'
            },
            pt: {
              xs: 'var(--ds-spacing-mild)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        >
          <DsStack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{
              xs: 'var(--ds-spacing-warm)',
              md: 'var(--ds-spacing-plasma)'
            }}
            sx={{
              alignItems: {
                xs: 'center',
                md: 'flex-start'
              }
            }}
          >
            <DsImage
              srcSet={contactUsImage}
              aspectRatio={545 / 545}
              WrapperProps={{ sx: { maxWidth: '400px' } }}
              style={{ width: '100%' }}
            />
            <DsStack direction="column" sx={{ flexGrow: 1 }}>
              <DsTypography
                variant="displayBoldLarge"
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-mild)',
                    md: 'var(--ds-spacing-hot)'
                  }
                }}
              >
                <DsTypography component="span" variant="inherit">
                  It almost always begins with a
                </DsTypography>
                <DsTypography
                  component="span"
                  variant="inherit"
                  color="primary"
                >
                  {' '}
                  conversation
                </DsTypography>
              </DsTypography>

              <DsTypography
                color="text.secondary"
                variant="bodyRegularLarge"
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-warm)',
                    md: 'var(--ds-spacing-tropical)'
                  }
                }}
              >
                Tell us more about your vision and we will help you craft
                delightful digital product experiences for your enterprices.
              </DsTypography>

              <DsStack
                direction="column"
                spacing={{ xs: 'var(--ds-spacing-mild)' }}
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-warm)',
                    md: 'var(--ds-spacing-tropical)'
                  }
                }}
              >
                <DsTypography variant="headingBoldSmall">
                  For general enquiries
                </DsTypography>

                <DsBox>
                  <DsTypography
                    component="p"
                    variant="bodyRegularLarge"
                    sx={{ mb: 'var(--ds-spacing-frostbite)' }}
                  >
                    write to:
                  </DsTypography>
                  <ButtonPrimary
                    size="medium"
                    startIcon={<DsRemixIcon className="ri-mail-open-fill" />}
                    onClick={this.handleMailToClick}
                  >
                    {MAIL_ID}
                  </ButtonPrimary>
                </DsBox>

                <DsStack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={{ xs: 'var(--ds-spacing-mild)' }}
                >
                  <DsBox>
                    <DsTypography
                      component="p"
                      variant="bodyRegularLarge"
                      sx={{ mb: 'var(--ds-spacing-frostbite)' }}
                    >
                      whatsapp:
                    </DsTypography>
                    <ButtonPrimary
                      size="medium"
                      startIcon={<DsRemixIcon className="ri-whatsapp-fill" />}
                      onClick={this.handleWhatsappClick}
                    >
                      {WHATSAPP_NUMBER}
                    </ButtonPrimary>
                  </DsBox>
                  <DsBox>
                    <DsTypography
                      component="p"
                      variant="bodyRegularLarge"
                      sx={{ mb: 'var(--ds-spacing-frostbite)' }}
                    >
                      call:
                    </DsTypography>
                    <ButtonPrimary
                      size="medium"
                      startIcon={<DsRemixIcon className="ri-phone-fill" />}
                      onClick={this.handleCallClick}
                    >
                      {CALL_NUMBER}
                    </ButtonPrimary>
                  </DsBox>
                </DsStack>
              </DsStack>

              <DsStack
                direction="column"
                spacing={{ xs: 'var(--ds-spacing-mild)' }}
              >
                <DsTypography variant="headingBoldSmall">
                  Our address
                </DsTypography>

                <DsBox>
                  <DsTypography
                    component="div"
                    variant="bodyRegularLarge"
                    sx={{ mb: 'var(--ds-spacing-frostbite)' }}
                  >
                    <DsTypography variant="inherit" gutterBottom>
                      AERIZO INFOTECH
                    </DsTypography>
                    <DsTypography variant="inherit" gutterBottom>
                      A/1501, Oberoi Sky Heights,
                    </DsTypography>
                    <DsTypography variant="inherit" gutterBottom>
                      Lokhandwala Complex, Andheri West
                    </DsTypography>
                    <DsTypography variant="inherit" gutterBottom>
                      Mumbai - 400 053, Maharashtra, India
                    </DsTypography>
                  </DsTypography>
                  <ButtonPrimary
                    size="medium"
                    startIcon={<DsRemixIcon className="ri-map-pin-2-fill" />}
                    onClick={this.handleLocationClick}
                  >
                    Get me there
                  </ButtonPrimary>
                </DsBox>
              </DsStack>
            </DsStack>
          </DsStack>
        </DsContainer>
      </DsFade>
    )
  }
}
