import React, { PureComponent } from 'react'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import {
  DsBox,
  DsDivider,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import ButtonPrimary from '~/src/Components/ButtonPrimary'
import ButtonSecondary from '~/src/Components/ButtonSecondary'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

export interface IFooterProps extends IWithRouterProps {}

class Footer extends PureComponent<IFooterProps> {
  render() {
    return (
      <DsBox>
        <DsStack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{
            xs: 'var(--ds-spacing-tropical)',
            md: 'var(--ds-spacing-meltdown)'
          }}
          sx={{
            backgroundColor: 'var(--ds-colour-actionSecondary)',
            p: {
              xs: 'var(--ds-spacing-warm)',
              md: 'var(--ds-spacing-molten)'
            },
            mb: 'var(--ds-spacing-molten)',
            justifyContent: 'center'
          }}
        >
          <DsStack
            direction="column"
            spacing="var(--ds-spacing-mild)"
            sx={{ alignItems: 'center' }}
          >
            <DsTypography
              variant="bodyRegularLarge"
              sx={{ color: 'var(--ds-colour-typoOnSurface)' }}
            >
              What can we help you achieve?
            </DsTypography>
            <ButtonPrimary
              onClick={() =>
                this.props.navigateTo(APP_ROUTES.SERVICES.pathname)
              }
            >
              Let's get to work
            </ButtonPrimary>
          </DsStack>

          <DsDivider sx={{ borderColor: 'var(--ds-colour-actionPrimary)' }} />

          <DsStack
            direction="column"
            spacing="var(--ds-spacing-mild)"
            sx={{ alignItems: 'center' }}
          >
            <DsTypography
              variant="bodyRegularLarge"
              sx={{ color: 'var(--ds-colour-typoOnSurface)' }}
            >
              Where will your career take you?
            </DsTypography>
            <ButtonSecondary
              onClick={() => this.props.navigateTo(APP_ROUTES.CAREER.pathname)}
            >
              Come and find out
            </ButtonSecondary>
          </DsStack>
        </DsStack>
        <DsBox
          sx={{
            pr: 'var(--ds-spacing-frostbite)',
            pb: 'var(--ds-spacing-frostbite)',
            pl: 'var(--ds-spacing-frostbite)'
          }}
        >
          <DsDivider sx={{ mb: 'var(--ds-spacing-frostbite)' }} />
          <DsTypography
            component="div"
            color="text.secondary"
            variant="supportRegularInfo"
            sx={{ textAlign: 'right' }}
          >
            Aerizo Infotech © {new Date().getFullYear()}
          </DsTypography>
        </DsBox>
      </DsBox>
    )
  }
}

export default withRouter(Footer)
