import React, { PureComponent } from 'react'
import { DsBox, DsTypography } from '@am92/react-design-system'

export interface IServiceProps {
  title: string
  description: string
}

export default class Service extends PureComponent<IServiceProps> {
  render() {
    const { title, description } = this.props
    return (
      <DsBox
        sx={{
          height: '100%',
          backgroundColor: 'var(--ds-colour-neutral5)',
          p: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-bitterCold)'
          }
        }}
      >
        <DsTypography
          component="div"
          variant="headingBoldSmall"
          color="primary"
          sx={{ mb: 'var(--ds-spacing-frostbite)' }}
        >
          {title}
        </DsTypography>
        <DsTypography
          component="div"
          variant="bodyRegularLarge"
          color="text.secondary"
        >
          {description}
        </DsTypography>
      </DsBox>
    )
  }
}
