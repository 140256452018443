import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  HOME: {
    pathname: '/'
  } as AppRouteItem,

  SERVICES: {
    pathname: '/services'
  } as AppRouteItem,

  PRODUCTS: {
    pathname: '/products'
  } as AppRouteItem,

  CONTACT_US: {
    pathname: '/contact-us'
  } as AppRouteItem,

  CAREER: {
    pathname: '/career'
  } as AppRouteItem,

  PORTFOLIO: {
    pathname: '/portfolio'
  } as AppRouteItem,

  ANY: {
    pathname: '*'
  } as AppRouteItem
}

export default APP_ROUTES
