import React, { PureComponent } from 'react'
import {
  DsBackdrop,
  DsBackdropProps,
  DsCircularProgress
} from '@am92/react-design-system'

export interface ILoaderProps extends Omit<DsBackdropProps, 'open'> {}

export default class Loader extends PureComponent<ILoaderProps> {
  render() {
    return (
      <DsBackdrop open {...this.props}>
        <DsCircularProgress />
      </DsBackdrop>
    )
  }
}
