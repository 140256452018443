import React, { PureComponent } from 'react'
import {
  DsBox,
  DsImage,
  DsLink,
  DsStack,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import { Swiper, SwiperSlide } from 'swiper/swiper-react'
import {
  EffectCoverflow,
  Autoplay,
  Navigation,
  Pagination
} from 'swiper/modules'

import ramaniHomeDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniHome'
import ramaniAboutUsDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniAboutUs'
import ramaniPracticeAreasDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniPracticeAreas'
import ramaniBooksAuthoredDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniBooksAuthored'
import ramaniLecturesDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniLectures'
import ramaniDownloadsDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniDownloads'
import ramaniContactUsDesktop from '~/src/Assets/portfolio/ramanilegal/desktop/ramaniContactUs'

import ramaniHomeMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniHome'
import ramaniAboutUsMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniAboutUs'
import ramaniPracticeAreasMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniPracticeAreas'
import ramaniBooksAuthoredMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniBooksAuthored'
import ramaniLecturesMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniLectures'
import ramaniDownloadsMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniDownloads'
import ramaniContactUsMobile from '~/src/Assets/portfolio/ramanilegal/mobile/ramaniContactUs'

import 'swiper/swiper.min.css'
import '~/public/assets/css/swiper.css'
import 'swiper/modules/navigation.min.css'
import 'swiper/modules/pagination.min.css'

const sliderImagesDesktop = [
  ramaniHomeDesktop,
  ramaniAboutUsDesktop,
  ramaniPracticeAreasDesktop,
  ramaniBooksAuthoredDesktop,
  ramaniLecturesDesktop,
  ramaniDownloadsDesktop,
  ramaniContactUsDesktop
]

const sliderImagesMobile = [
  ramaniHomeMobile,
  ramaniAboutUsMobile,
  ramaniPracticeAreasMobile,
  ramaniBooksAuthoredMobile,
  ramaniLecturesMobile,
  ramaniDownloadsMobile,
  ramaniContactUsMobile
]

export interface IRamaniLegalProps extends IwithBreakpoints {}

class RamaniLegal extends PureComponent<IRamaniLegalProps> {
  render() {
    const { breakpoints } = this.props

    const sliderImages =
      breakpoints.xs || breakpoints.sm
        ? sliderImagesMobile
        : sliderImagesDesktop

    const slidesPerView = breakpoints.xs || breakpoints.sm ? 1.6 : 1.4

    const aspectRatio =
      breakpoints.xs || breakpoints.sm ? 1170 / 2532 : 3024 / 1889

    return (
      <DsStack
        id="ramanilegal"
        direction="column"
        spacing="var(--ds-spacing-warm)"
      >
        <DsBox>
          <DsTypography
            component="div"
            variant="displayBoldLarge"
            sx={{ mb: 'var(--ds-spacing-glacial)' }}
          >
            Ramani Legal
          </DsTypography>
          <DsTypography
            component="div"
            variant="headingBoldExtraLarge"
            color="primary"
          >
            Responsive Website
          </DsTypography>
        </DsBox>

        <DsBox
          sx={{
            pt: 'var(--ds-spacing-warm)',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)'
          }}
        >
          <Swiper
            loop
            autoplay
            navigation
            pagination={{ clickable: true }}
            grabCursor
            centeredSlides
            effect="coverflow"
            slidesPerView={slidesPerView}
            modules={[EffectCoverflow, Autoplay, Navigation, Pagination]}
            style={{ paddingBottom: 'var(--ds-spacing-tropical)' }}
          >
            {sliderImages.map((sliderImage, index) => (
              <SwiperSlide key={index}>
                <DsImage
                  srcSet={sliderImage}
                  aspectRatio={aspectRatio}
                  WrapperProps={{
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    boxShadow: 'var(--ds-elevation-0)'
                  }}
                  style={{ display: 'block' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </DsBox>

        <DsStack direction="column" spacing="var(--ds-spacing-bitterCold)">
          <DsTypography variant="bodyRegularLarge" color="text.secondary">
            We proudly present a mobile-responsive website crafted for a
            distinguished legal firm in India. Meticulously optimized for high
            performance and rapid page loads, we utilized Google's{' '}
            <DsLink
              variant="inherit"
              underline="always"
              href="https://developers.google.com/speed/docs/insights/v5/about"
              target="_blank"
            >
              Pagespeed Insights
            </DsLink>{' '}
            to refine the user experience on both mobile and desktop devices.
            The deployment and management on AWS Cloud, coupled with a strategic
            Content Delivery Network (CDN), ensure global accessibility with
            minimized page load times.
          </DsTypography>

          <DsTypography variant="bodyRegularLarge" color="text.secondary">
            This project exemplifies our commitment to delivering top-tier web
            solutions for clients, emphasizing not just functionality but an
            optimal user experience.
          </DsTypography>

          <DsTypography variant="bodyRegularLarge" color="text.secondary">
            You can checkout the complete website experience on{' '}
            <DsLink
              variant="inherit"
              underline="always"
              href="https://ramanilegal.com"
              target="_blank"
            >
              https://ramanilegal.com
            </DsLink>
          </DsTypography>
        </DsStack>
      </DsStack>
    )
  }
}

export default withBreakpoints(RamaniLegal)
