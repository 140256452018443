import React, { PureComponent } from 'react'
import {
  DsBox,
  DsContainer,
  DsFade,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import ButtonSecondary from '~/src/Components/ButtonSecondary'
import careerImage from '~/src/Assets/career/careerImage'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

export interface ICareerPageProps extends IWithRouterProps {}

class CareerPage extends PureComponent<ICareerPageProps> {
  render() {
    return (
      <DsFade in>
        <DsContainer
          sx={{
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite)'
            },
            pt: {
              xs: 'var(--ds-spacing-mild)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        >
          <DsStack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{
              xs: 'var(--ds-spacing-warm)',
              md: 'var(--ds-spacing-plasma)'
            }}
            sx={{
              alignItems: {
                xs: 'center',
                md: 'flex-start'
              }
            }}
          >
            <DsImage
              srcSet={careerImage}
              aspectRatio={525 / 525}
              WrapperProps={{ sx: { maxWidth: '400px' } }}
              style={{ width: '100%' }}
            />
            <DsStack direction="column" sx={{ flexGrow: 1 }}>
              <DsTypography
                variant="displayBoldLarge"
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-mild)',
                    md: 'var(--ds-spacing-hot)'
                  }
                }}
              >
                <DsTypography component="span" variant="inherit">
                  Do the best work
                </DsTypography>
                <DsTypography
                  component="span"
                  variant="inherit"
                  color="primary"
                >
                  {' '}
                  of your life
                </DsTypography>
              </DsTypography>

              <DsTypography
                color="text.secondary"
                variant="bodyRegularLarge"
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-bitterCold)',
                    md: 'var(--ds-spacing-warm)'
                  }
                }}
              >
                Join our team of hybrid, diverse talents to challenge the status
                quo and solve problems.
              </DsTypography>

              <DsBox>
                <ButtonSecondary
                  sx={{ width: { xs: '100%', md: 'auto' } }}
                  onClick={() =>
                    this.props.navigateTo(APP_ROUTES.CONTACT_US.pathname)
                  }
                >
                  Connect With Us
                </ButtonSecondary>
              </DsBox>
            </DsStack>
          </DsStack>
        </DsContainer>
      </DsFade>
    )
  }
}

export default withRouter(CareerPage)
