import React, { PureComponent } from 'react'
import {
  DsBox,
  DsImage,
  DsStack,
  DsTypography,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import ButtonPrimary from '~/src/Components/ButtonPrimary'
import { Swiper, SwiperSlide } from 'swiper/swiper-react'
import {
  EffectCoverflow,
  Autoplay,
  Navigation,
  Pagination
} from 'swiper/modules'

import renergizeLogo from '~/src/Assets/products/renergize/renergizeLogo'
import renergizeShowcase1 from '~/src/Assets/products/renergize/renergizeShowcase1'
import renergizeShowcase2 from '~/src/Assets/products/renergize/renergizeShowcase2'
import renergizeShowcase3 from '~/src/Assets/products/renergize/renergizeShowcase3'
import renergizeShowcase4 from '~/src/Assets/products/renergize/renergizeShowcase4'

import 'swiper/swiper.min.css'
import '~/public/assets/css/swiper.css'
import 'swiper/modules/navigation.min.css'
import 'swiper/modules/pagination.min.css'

const sliderImages = [
  renergizeShowcase1,
  renergizeShowcase2,
  renergizeShowcase3,
  renergizeShowcase4
]

export interface IRenergizeProps extends IwithBreakpoints {}

class Renergize extends PureComponent<IRenergizeProps> {
  handleDownloadClick = () => {
    window.location.href = 'https://renergize.io'
  }

  renderDescription = () => (
    <>
      <DsTypography variant="bodyRegularLarge" color="text.secondary">
        Renergize, our innovative app designed for Electric Vehicle (EV) owners,
        offers a seamless experience in locating charging stations. Boasting a
        user-friendly interface and a modern design, the app ensures ease of use
        for all users.
      </DsTypography>
      <DsTypography variant="bodyRegularLarge" color="text.secondary">
        With a comprehensive roadmap, our goal is to elevate the entire EV
        experience by addressing the diverse needs of electric vehicle users
        through a single application.
      </DsTypography>
      <DsTypography variant="bodyRegularLarge" color="text.secondary">
        The app is available on both App Store and Play Store.
      </DsTypography>
      <DsBox sx={{ pt: 'var(--ds-spacing-bitterCold)' }}>
        <ButtonPrimary
          onClick={this.handleDownloadClick}
          sx={{ width: { xs: '100%', md: 'auto' } }}
        >
          Download App
        </ButtonPrimary>
      </DsBox>
    </>
  )

  render() {
    const { breakpoints } = this.props
    const slidesPerView = breakpoints.xs || breakpoints.sm ? 1.5 : 2.5

    return (
      <DsStack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{
          xs: 'var(--ds-spacing-warm)',
          md: 'var(--ds-spacing-molten)'
        }}
        sx={{ alignItems: { xs: 'unset', md: 'center' } }}
      >
        <DsBox sx={{ flex: 1.5 }}>
          <DsBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: {
                xs: 'var(--ds-spacing-zero)',
                md: 'var(--ds-spacing-hot)'
              }
            }}
          >
            <DsImage
              srcSet={renergizeLogo}
              aspectRatio={1 / 1}
              WrapperProps={{
                sx: {
                  height: { xs: '75px', md: '120px' },
                  width: 'auto',
                  mr: 'var(--ds-spacing-bitterCold)'
                }
              }}
              style={{
                height: '100%',
                borderRadius: 'var(--ds-radius-quickFreeze)'
              }}
            />
            <DsTypography variant="displayBoldLarge" sx={{ flexGrow: 1 }}>
              <DsTypography component="div" variant="inherit">
                Renergize
              </DsTypography>
              <DsTypography
                component="div"
                variant="headingBoldExtraLarge"
                color="primary"
              >
                EV Station Finder
              </DsTypography>
            </DsTypography>
          </DsBox>

          <DsStack
            direction="column"
            spacing="var(--ds-spacing-bitterCold)"
            sx={{
              mb: {
                xs: 'var(--ds-spacing-bitterCold)',
                md: 'var(--ds-spacing-warm)'
              },
              display: { xs: 'none', md: 'flex' }
            }}
          >
            {this.renderDescription()}
          </DsStack>
        </DsBox>

        <DsBox
          sx={{
            flex: 1,
            pt: 'var(--ds-spacing-warm)',
            backgroundColor: 'var(--ds-colour-surfaceSecondary)',
            overflowY: 'hidden'
          }}
        >
          <Swiper
            loop
            autoplay
            navigation
            pagination={{ clickable: true }}
            grabCursor
            centeredSlides
            effect="coverflow"
            slidesPerView={slidesPerView}
            modules={[EffectCoverflow, Autoplay, Navigation, Pagination]}
            style={{ paddingBottom: 'var(--ds-spacing-tropical)' }}
          >
            {sliderImages.map((sliderImage, index) => (
              <SwiperSlide key={index}>
                <DsImage
                  srcSet={sliderImage}
                  aspectRatio={1284 / 2778}
                  WrapperProps={{
                    border: '1px solid var(--ds-colour-strokeDefault)',
                    boxShadow: 'var(--ds-elevation-0)'
                  }}
                  style={{ display: 'block' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </DsBox>

        <DsStack
          direction="column"
          spacing="var(--ds-spacing-bitterCold)"
          sx={{
            mb: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-warm)'
            },
            display: { xs: 'flex', md: 'none' }
          }}
        >
          {this.renderDescription()}
        </DsStack>
      </DsStack>
    )
  }
}
export default withBreakpoints(Renergize)
