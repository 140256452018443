import React from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

import MainLayout from '~/src/Layouts/Main.Layout'

import HomePage from '~/src/Pages/Home/Home.Page'
import ServicesPage from '~/src/Pages/Services/Services.Page'
import ProductsPage from '~/src/Pages/Products/Products.Page'
import PortfolioPage from '~/src/Pages/Portfolio/Portfolio.Page'
import CareerPage from '~/src/Pages/Career/Career.Page'
import ContactUsPage from '~/src/Pages/ContactUs/ContactUs.Page'
import NotFoundPage from '~/src/Pages/NotFound/NotFound.Page'

// const HomePage = React.lazy(() => import('~/src/Pages/Home/Home.Page'))

// const ServicesPage = React.lazy(
//   () => import('~/src/Pages/Services/Services.Page')
// )

// const ProductsPage = React.lazy(
//   () => import('~/src/Pages/Products/Products.Page')
// )

// const PortfolioPage = React.lazy(
//   () => import('~/src/Pages/Portfolio/Portfolio.Page')
// )

// const CareerPage = React.lazy(() => import('~/src/Pages/Career/Career.Page'))

// const ContactUsPage = React.lazy(
//   () => import('~/src/Pages/ContactUs/ContactUs.Page')
// )

// const NotFoundPage = React.lazy(
//   () => import('~/src/Pages/NotFound/NotFound.Page')
// )

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.pathname,
        element: <HomePage />
      } as RouteObject,
      {
        path: APP_ROUTES.SERVICES.pathname,
        element: <ServicesPage />
      } as RouteObject,
      {
        path: APP_ROUTES.PRODUCTS.pathname,
        element: <ProductsPage />
      } as RouteObject,
      {
        path: APP_ROUTES.PORTFOLIO.pathname,
        element: <PortfolioPage />
      } as RouteObject,
      {
        path: APP_ROUTES.CAREER.pathname,
        element: <CareerPage />
      } as RouteObject,
      {
        path: APP_ROUTES.CONTACT_US.pathname,
        element: <ContactUsPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    element: <NotFoundPage />
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
