import React, { PureComponent } from 'react'
import {
  DsBox,
  DsContainer,
  DsFade,
  DsGrid,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import Service from './Components/Service'
import ButtonSecondary from '~/src/Components/ButtonSecondary'
import servicesImage from '~/src/Assets/services/servicesImage'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { SERVICES } from './Services.Constants'

export interface IServicesPageProps extends IWithRouterProps {}

class ServicesPage extends PureComponent<IServicesPageProps> {
  render() {
    return (
      <DsFade in>
        <DsContainer
          sx={{
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite)'
            },
            pt: {
              xs: 'var(--ds-spacing-mild)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        >
          <DsStack
            direction="column"
            spacing={{
              xs: 'var(--ds-spacing-warm)',
              md: 'var(--ds-spacing-hot)'
            }}
          >
            <DsStack
              direction={{ xs: 'column', md: 'row-reverse' }}
              spacing={{
                xs: 'var(--ds-spacing-warm)',
                md: 'var(--ds-spacing-plasma)'
              }}
              sx={{
                alignItems: {
                  xs: 'center',
                  md: 'flex-start'
                }
              }}
            >
              <DsImage
                srcSet={servicesImage}
                aspectRatio={606 / 632}
                WrapperProps={{ sx: { maxWidth: '400px' } }}
                style={{ width: '100%' }}
              />
              <DsStack direction="column" sx={{ flexGrow: 1 }}>
                <DsTypography
                  variant="displayBoldLarge"
                  sx={{
                    mb: {
                      xs: 'var(--ds-spacing-mild)',
                      md: 'var(--ds-spacing-hot)'
                    }
                  }}
                >
                  <DsTypography component="span" variant="inherit">
                    Aerizo
                    <DsTypography
                      component="span"
                      variant="displayBoldItalicLarge"
                    >
                      {' '}
                      Infotech
                    </DsTypography>
                  </DsTypography>
                  <DsTypography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    {' '}
                    Capabilities
                  </DsTypography>
                </DsTypography>

                <DsTypography
                  color="text.secondary"
                  variant="bodyRegularLarge"
                  sx={{
                    mb: {
                      xs: 'var(--ds-spacing-bitterCold)',
                      md: 'var(--ds-spacing-warm)'
                    }
                  }}
                >
                  Call it what you want - innovation, disruption or
                  transformation - it touches every part of your business. We
                  are your turnkey partner. We are known for our agile
                  entrepreneurship, for our client centricity, for the value we
                  generate. And even for how fun it is to work with us!
                </DsTypography>

                <DsBox>
                  <ButtonSecondary
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    onClick={() =>
                      this.props.navigateTo(APP_ROUTES.CONTACT_US.pathname)
                    }
                  >
                    Connect With Us
                  </ButtonSecondary>
                </DsBox>
              </DsStack>
            </DsStack>

            <DsTypography variant="headingBoldExtraLarge">
              How we help you
            </DsTypography>

            <DsBox>
              <DsGrid
                container
                spacing={{ xs: 4, md: 8 }}
                sx={{
                  ml: {
                    xs: 'calc(var(--ds-spacing-bitterCold) * -1) !important',
                    md: 'calc(var(--ds-spacing-warm) * -1) !important'
                  }
                }}
              >
                {SERVICES.map((service, index) => (
                  <DsGrid key={index} item xs={12} lg={4}>
                    <Service
                      title={service.title}
                      description={service.description}
                    />
                  </DsGrid>
                ))}
              </DsGrid>
            </DsBox>
          </DsStack>
        </DsContainer>
      </DsFade>
    )
  }
}

export default withRouter(ServicesPage)
