const logo = [
  {
    src: new URL('~/public/assets/images/logo.svg?as=avif', import.meta.url)
      .href,
    alt: 'Logo',
    as: 'image/avif'
  },
  {
    src: new URL('~/public/assets/images/logo.svg', import.meta.url).href,
    alt: 'Logo',
    as: 'image/png'
  }
]

export default logo
