const ramaniPracticeAreasMobile = [
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/mobile/practiceAreas.png?width=300px&as=avif',
      import.meta.url
    ).href,
    alt: 'Practice Areas',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/mobile/practiceAreas.png?width=300px',
      import.meta.url
    ).href,
    alt: 'Practice Areas',
    as: 'image/png'
  }
]

export default ramaniPracticeAreasMobile
