import React, { PureComponent } from 'react'
import { DsButton, DsButtonProps } from '@am92/react-design-system'

export default class ButtonPrimary extends PureComponent<DsButtonProps> {
  render() {
    const { ref, ...restProps } = this.props
    return (
      <DsButton
        size="large"
        {...restProps}
        variant="contained"
        color="primary"
        sx={{
          color: 'var(--ds-colour-textPrimary)',
          borderRadius: '0px',
          ...restProps?.sx
        }}
      />
    )
  }
}
