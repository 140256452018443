import React, { Component } from 'react'
import {
  DsBox,
  DsDivider,
  DsDrawer,
  DsIconButton,
  DsRemixIcon,
  DsTab,
  DsTabs,
  DsTypography
} from '@am92/react-design-system'
import NAV_LINKS from '~/src/Constants/NAV_LINKS'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

export interface INavLinksMobileProps extends IWithRouterProps {}

export interface INavLinksMobileState {
  drawerOpen: boolean
}

class NavLinksMobile extends Component<
  INavLinksMobileProps,
  INavLinksMobileState
> {
  state = {
    drawerOpen: false
  }

  handleOpenDrawer = () => this.setState({ drawerOpen: true })

  handleCloseDrawer = () => this.setState({ drawerOpen: false })

  handleNavChange = (event: React.SyntheticEvent, value: string) => {
    this.setState({ drawerOpen: false })
    this.props.navigateTo(value)
  }

  render() {
    const { location } = this.props
    const { drawerOpen } = this.state
    const tabValue = location.pathname

    return (
      <>
        <DsIconButton
          sx={{ display: { xs: 'flex', md: 'none' } }}
          onClick={this.handleOpenDrawer}
        >
          <DsRemixIcon className="ri-menu-line" color="primary" />
        </DsIconButton>

        <DsDrawer
          anchor="left"
          open={drawerOpen}
          onClose={this.handleCloseDrawer}
          PaperProps={{
            sx: {
              backgroundColor: 'var(--ds-colour-actionSecondary)'
            }
          }}
        >
          <DsBox
            sx={{
              p: 'var(--ds-spacing-bitterCold)',
              color: 'var(--ds-colour-iconOnSurface)'
            }}
          >
            <DsTypography
              variant="headingBoldExtraSmall"
              sx={{ fontWeight: '400' }}
            >
              NAVIGATIONS
            </DsTypography>
          </DsBox>

          <DsDivider sx={{ mb: 'var(--ds-spacing-bitterCold)' }} />

          <DsTabs
            value={tabValue}
            onChange={this.handleNavChange}
            orientation="vertical"
            TabIndicatorProps={{ sx: { left: 0 } }}
          >
            {NAV_LINKS.map((navLink, index) => (
              <DsTab
                key={index}
                value={navLink.path}
                sx={{
                  alignItems: 'flex-start',
                  pr: 'var(--ds-spacing-plasma)',
                  mt: index ? 'var(--ds-spacing-bitterCold)' : undefined
                }}
                label={
                  <DsTypography
                    variant="headingBoldSmall"
                    sx={{
                      fontWeight: '400',
                      textTransform: 'none',
                      color:
                        navLink.path === tabValue
                          ? 'var(--ds-colour-actionPrimary)'
                          : 'var(--ds-colour-iconOnSurface)'
                    }}
                  >
                    {navLink.title}
                  </DsTypography>
                }
              />
            ))}
          </DsTabs>
        </DsDrawer>
      </>
    )
  }
}

export default withRouter(NavLinksMobile)
