import React, { PureComponent } from 'react'
import { DsTab, DsTabs, DsTypography } from '@am92/react-design-system'
import NAV_LINKS from '~/src/Constants/NAV_LINKS'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

export interface INavLinksDesktopProps extends IWithRouterProps {}

class NavLinksDesktop extends PureComponent<INavLinksDesktopProps> {
  handleNavChange = (event: React.SyntheticEvent, value: string) => {
    this.props.navigateTo(value)
  }

  render() {
    const tabValue = this.props.location.pathname

    return (
      <DsTabs
        value={tabValue}
        onChange={this.handleNavChange}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        {NAV_LINKS.map((navLink, index) => (
          <DsTab
            key={index}
            sx={{ ml: index ? 'var(--ds-spacing-pleasant)' : undefined }}
            value={navLink.path}
            label={
              <DsTypography
                variant="headingBoldSmall"
                sx={{
                  fontWeight: '400',
                  textTransform: 'none',
                  color:
                    navLink.path === tabValue
                      ? 'var(--ds-colour-actionPrimary)'
                      : 'var(--ds-colour-iconOnSurface)'
                }}
              >
                {navLink.title}
              </DsTypography>
            }
          />
        ))}
      </DsTabs>
    )
  }
}

export default withRouter(NavLinksDesktop)
