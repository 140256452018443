import APP_ROUTES from './APP_ROUTES'

const NAV_LINKS = [
  {
    title: 'About',
    path: APP_ROUTES.HOME.pathname
  },
  {
    title: 'Services',
    path: APP_ROUTES.SERVICES.pathname
  },
  {
    title: 'Products',
    path: APP_ROUTES.PRODUCTS.pathname
  },
  {
    title: 'Portfolio',
    path: APP_ROUTES.PORTFOLIO.pathname
  },
  {
    title: 'Career',
    path: APP_ROUTES.CAREER.pathname
  },
  {
    title: 'Get In Touch',
    path: APP_ROUTES.CONTACT_US.pathname
  }
]

export default NAV_LINKS
