import React, { PureComponent } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Components/Header'
import Footer from './Components/Footer'
import { DsContainer } from '@am92/react-design-system'
import Highlight from './Components/Highlight'

export default class MainLayout extends PureComponent {
  render() {
    return (
      <>
        <Header />
        <Highlight />
        {/* <DsContainer
          sx={{
            flex: 1,
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite)'
            },
            pt: {
              xs: 'var(--ds-spacing-mild)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        > */}
        <Outlet />
        {/* </DsContainer> */}
        <Footer />
      </>
    )
  }
}
