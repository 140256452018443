const careerImage = [
  {
    src: new URL(
      '~/public/assets/images/career/career.png?width=400px&as=avif',
      import.meta.url
    ).href,
    alt: 'Career',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~/public/assets/images/career/career.png?width=400px',
      import.meta.url
    ).href,
    alt: 'Career',
    as: 'image/png'
  }
]

export default careerImage
