import { DsPalette, SupportedColorScheme } from '@am92/react-design-system'
import { APP_TITLE } from '~/src/Configurations/env'

export const PALETTE: DsPalette = {
  primary: '#D8C084',
  secondary100: '#171717',
  secondary80: '#171717',
  secondary60: '#171717',
  secondary40: '#171717',
  secondary20: '#171717'
}

export const FONT_FAMILY: string = 'Roboto Mono'

export const DEFAULT_THEME_MODE: SupportedColorScheme = 'light'

export const THEME_MODE_STORAGE_KEY: string = `${APP_TITLE.replaceAll(
  ' ',
  '-'
)}-mode`
