import React from 'react'
import {
  DsContainer,
  DsFade,
  DsImage,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import homeImage from '~/src/Assets/home/homeImage'
import ButtonPrimary from '~/src/Components/ButtonPrimary'
import ButtonSecondary from '~/src/Components/ButtonSecondary'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'

interface IHomePageProps extends IWithRouterProps {}

class HomePage extends React.Component<IHomePageProps> {
  render() {
    return (
      <DsFade in>
        <DsContainer
          sx={{
            px: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-frostbite)'
            },
            pt: {
              xs: 'var(--ds-spacing-mild)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        >
          <DsStack
            direction={{ xs: 'column', md: 'row-reverse' }}
            spacing={{
              xs: 'var(--ds-spacing-warm)',
              md: 'var(--ds-spacing-plasma)'
            }}
            sx={{
              alignItems: {
                xs: 'center',
                md: 'flex-start'
              }
            }}
          >
            <DsImage
              srcSet={homeImage}
              aspectRatio={537 / 529}
              WrapperProps={{ sx: { maxWidth: '400px' } }}
              style={{ width: '100%' }}
            />
            <DsStack direction="column" sx={{ flexGrow: 1 }}>
              <DsTypography
                variant="displayBoldLarge"
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-mild)',
                    md: 'var(--ds-spacing-hot)'
                  }
                }}
              >
                <DsTypography component="span" variant="inherit">
                  We'll help you
                </DsTypography>
                <DsTypography
                  component="span"
                  variant="inherit"
                  color="primary"
                >
                  {' '}
                  transform
                </DsTypography>
              </DsTypography>

              <DsTypography
                color="text.secondary"
                variant="bodyRegularLarge"
                sx={{
                  mb: {
                    xs: 'var(--ds-spacing-bitterCold)',
                    md: 'var(--ds-spacing-warm)'
                  }
                }}
              >
                From building new digital businesses and helping organizations
                to modernize their technology, to transforming companies through
                data, analytics, and artificial intelligence, AERIZO Infotech
                delivers high-value end-to-end business transformation.
              </DsTypography>

              <DsStack
                direction={{ xs: 'column', md: 'row' }}
                spacing={{
                  xs: 'var(--ds-spacing-bitterCold)',
                  md: 'var(--ds-spacing-mild)'
                }}
              >
                <ButtonPrimary
                  onClick={() =>
                    this.props.navigateTo(APP_ROUTES.SERVICES.pathname)
                  }
                >
                  Our Services
                </ButtonPrimary>
                <ButtonSecondary
                  onClick={() =>
                    this.props.navigateTo(APP_ROUTES.CONTACT_US.pathname)
                  }
                >
                  Connect With Us
                </ButtonSecondary>
              </DsStack>
            </DsStack>
          </DsStack>
        </DsContainer>
      </DsFade>
    )
  }
}

export default withRouter(HomePage)
