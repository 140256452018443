const ramaniAboutUsDesktop = [
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/desktop/aboutUs.png?width=1200px&as=avif',
      import.meta.url
    ).href,
    alt: 'About Us',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/desktop/aboutUs.png?width=1200px',
      import.meta.url
    ).href,
    alt: 'About Us',
    as: 'image/png'
  }
]

export default ramaniAboutUsDesktop
