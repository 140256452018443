const ramaniDownloadsDesktop = [
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/desktop/downloads.png?width=1200px&as=avif',
      import.meta.url
    ).href,
    alt: 'Downloads',
    as: 'image/avif'
  },
  {
    src: new URL(
      '~public/assets/images/portfolio/ramanilegal/desktop/downloads.png?width=1200px',
      import.meta.url
    ).href,
    alt: 'Downloads',
    as: 'image/png'
  }
]

export default ramaniDownloadsDesktop
