import React, { PureComponent } from 'react'
import {
  DsBox,
  DsContainer,
  DsDivider,
  DsFade
} from '@am92/react-design-system'
import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'
import RamaniLegal from './Components/RamaniLegal'
import Topshine from './Components/Topshine'

export interface IPortfolioPageProps extends IWithRouterProps {}

class PortfolioPage extends PureComponent<IPortfolioPageProps> {
  render() {
    return (
      <DsFade in>
        <DsBox
          sx={{
            pt: {
              xs: 'var(--ds-spacing-tropical)',
              md: 'var(--ds-spacing-meltdown)'
            },
            pb: {
              xs: 'var(--ds-spacing-molten)',
              md: 'var(--ds-spacing-meltdown)'
            }
          }}
        >
          <DsContainer
            sx={{
              px: {
                xs: 'var(--ds-spacing-bitterCold)',
                md: 'var(--ds-spacing-frostbite)'
              }
            }}
          >
            <RamaniLegal />
          </DsContainer>

          <DsDivider
            sx={{
              my: {
                xs: 'var(--ds-spacing-tropical)',
                md: 'var(--ds-spacing-molten)'
              }
            }}
          />

          <DsContainer
            sx={{
              px: {
                xs: 'var(--ds-spacing-bitterCold)',
                md: 'var(--ds-spacing-frostbite)'
              }
            }}
          >
            <Topshine />
          </DsContainer>
        </DsBox>
      </DsFade>
    )
  }
}

export default withRouter(PortfolioPage)
